import { useParams, useSearchParams } from "react-router-dom";
import { initSettingsDlst } from "../../data/initSetting";
import { getCustomProperty } from "../../utils/utils";
import ChatFieldBase from "../Common/ChatFieldBase";
import ChatFieldBaseDLST from "../Common/ChatFieldBaseDLST";

const ChatDailyshot = (props) => {
    const {variant} = props;
    const customProperty = getCustomProperty('dlst');
    let queryString = window.location.search;
    const [searchParams, setSearchParams] = new useSearchParams(queryString);
    const reqParams = {
        itemId: 'general',
        userId: useParams().demoId,
        type: searchParams.get('t'),
        isMobile: searchParams.get('ch'),
        floatingComment: null,
    }

    return (
        <ChatFieldBaseDLST
            customProperty={customProperty}
            customSet={initSettingsDlst}
            variant={variant}
            reqParams={reqParams}
        />
    );
};

export default ChatDailyshot;